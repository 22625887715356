$(document).ready(function () {
    $('.burger').click(function () {
        $('.menu').toggleClass('on');
        $('.close_btn').toggleClass('on');
        $('.burger').toggleClass('on');
    });
    $('.close_btn').click(function () {
        $('.menu').toggleClass('on');
        $('.close_btn').toggleClass('on');
        $('.burger').toggleClass('on');
    });
    $(function() {
        $("a[href*='#']:not([href='#'])").click(function() {
            if (location.hostname == this.hostname && this.pathname.replace(/^\//,"") == location.pathname.replace(/^\//,"")) {
                var anchor = $(this.hash);
                anchor = anchor.length ? anchor : $("[name=" + this.hash.slice(1) +"]");
                if (anchor.length) {
                    $("html, body").animate( { scrollTop: anchor.offset().top }, 1500);
                }
            }
        });
    });
    $('header .left-apparition').addClass('on');
    $('header .right-apparition').addClass('on');
    $('.btn-plus').click(function(){
        if ($(this).hasClass('on')) {
            $(this).removeClass('on');
        } else {
            $(this).addClass('on');
        }
    });
    if($('.oeil').length != 0) {
        var oeil_h = parseInt($('.oeil').outerHeight());
        var nez_h = parseInt($('.nez').outerHeight());
        var bouche_h = parseInt($('.bouche').outerHeight());
        var accord_h = parseInt($('.accord').outerHeight());
        var heights = [oeil_h, nez_h, bouche_h, accord_h];
        heights.sort(function(a, b) {
            return b-a;
        });
        $('.notes_outer').css('min-height', $(heights)[0]+'px');
        $('.notes').css('min-height', $(heights)[0]+'px');
    };
});

$(document).scroll(function() {
    scrollparalaxx();
    $('.left-apparition').each(function() {
        if(($(this).length != 0) && !($(this).hasClass('on'))) {
            var top_of_element = $(this).offset().top;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                $(this).addClass('on');
            }
        }
    });
    $('.right-apparition').each(function() {
        if(($(this).length != 0) && !($(this).hasClass('on'))) {
            var top_of_element = $(this).offset().top;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                $(this).addClass('on');
            }
        }
    });        
    $('.top-apparition').each(function() {
        if(($(this).length != 0) && !($(this).hasClass('on'))) {
            var top_of_element = $(this).offset().top;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                $(this).addClass('on');
            }
        }
    });
    $('.bottom-apparition').each(function() {
        if(($(this).length != 0) && !($(this).hasClass('on'))) {
            var top_of_element = $(this).offset().top;
            var bottom_of_element = $(this).offset().top + $(this).outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)) {
                $(this).addClass('on');
            }
        }
    });
});

function scrollparalaxx() {
    scrollPos = $(this).scrollTop();
    if($('.paralaxx_art').length != 0) {
        $('.paralaxx_art').css({
            'background-position': "50% " + scrollPos / 20 + "%"
        });
    }
    if($('.paralaxx_domaine').length != 0) {
        $('.paralaxx_domaine').css({
            'background-position': "50% " + scrollPos / 20 + "%"
        });
    }
}